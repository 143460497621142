import { useEffect, useState } from "react";

const useForm = (inputs) => {
  const [fields, setFields] = useState({});
  useEffect(() => {
    setFields(inputs);
  }, []);

  const setOne = (key, value) => {
    setFields((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const reset = () => {
    let empty = {};
    Object.keys(fields).forEach((key) => {
      empty[key] = "";
    });
    setFields(empty);
  };
  return {
    inputs: fields,
    setOne,
    setFields,
    reset,
  };
};

export default useForm;
