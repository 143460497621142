import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useMatch } from "react-router-dom";

const Authenticated = (props) => {
  const isAuth = useSelector((store) => store.auth.isAuthenticated);
  const location = useLocation();

  return (
    <div className="">
      {isAuth ? (
        <Outlet />
      ) : (
        <Navigate replace to={"/login"} state={{ path: location.pathname }} />
      )}
    </div>
  );
};

export default Authenticated;
