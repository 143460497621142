import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { bg01 } from "../../components/imageImport";
import { useSelector } from "react-redux";
import useRequest from "../../hooks/use-request";
import ProductCard from "../../components/product-card";
import SearchBar from "../../components/search-bar";
import { paramsToObject } from "../../utils/methods";

const Explore = () => {
  const navigate = useNavigate();

  const categories = useSelector((store) => store.ui.categories);
  const [products, setProducts] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  let [searchParams, setSearchParams] = useSearchParams();
  const { sendRequest, isLoading } = useRequest();

  const onLoadMore = async () => {
    if (!isLoading && nextPage) {
      await getProducts();
    }
  };

  const getProducts = useCallback(
    async (page = null) => {
      const response = await sendRequest({
        url: "/explore",
        queryParams: {
          ...paramsToObject(searchParams),
          page: page ?? nextPage,
        },
      });
      if (response) {
        setProducts((prev) => [...prev, ...response.products.data]);
        setNextPage(
          response.products.next_page_url
            ? response.products.current_page + 1
            : null
        );
      }
    },
    [nextPage, searchParams, sendRequest]
  );

  useEffect(() => {
    setProducts([]);
    setNextPage(1);
    getProducts(1);
  }, [searchParams]);

  return (
    <>
      {/* Navbar */}
      <Navbar />
      {/* Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Marketplace
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Explore the latest digital product of Code Bazaar
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="position-middle-bottom mb-5 pb-3">
            <nav aria-label="breadcrumb" className="d-block">
              <ul
                className="breadcrumb breadcrumb-muted mb-0 p-0"
                style={{ backgroundColor: "transparent" }}
              >
                <li className="breadcrumb-item">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                  >
                    Code Bazaar
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Explore
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}

      {/* Start */}
      <section className="section">
        <SearchBar />
        <div className="container">
          <div className="row justify-content-center mb-4 pb-2">
            <div className="col filters-group-wrap">
              <div className="filters-group">
                <ul className="container-filter mb-0 categories-filter text-center list-unstyled">
                  <li
                    onClick={() => {
                      let obj = paramsToObject(searchParams);
                      delete obj.category;
                      setSearchParams(obj);
                    }}
                    className={`list-inline-item categories position-relative text-dark ${
                      !searchParams.get("category") && "active"
                    }`}
                  >
                    All
                  </li>
                  {categories?.map((category) => (
                    <li
                      onClick={() =>
                        setSearchParams({
                          ...paramsToObject(searchParams),
                          category: category.id,
                        })
                      }
                      className={`list-inline-item categories position-relative text-dark ${
                        searchParams.get("category") == category.id && "active"
                      }`}
                    >
                      {category.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div
            className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 g-4"
            id="grid"
          >
            {products?.map((prod) => (
              <ProductCard product={prod} />
            ))}
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="row justify-content-center mt-4">
            {nextPage && (
              <div className="col">
                <div className="text-center">
                  <button
                    href=""
                    onClick={onLoadMore}
                    className="btn btn-primary rounded-md"
                  >
                    <i className="uil uil-process mdi-spin me-1"></i> Load More
                  </button>
                </div>
              </div>
            )}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End */}
      {/* footer */}
      <Footer />
    </>
  );
};

export default Explore;
