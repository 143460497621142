import { storage } from "../utils/methods";

const DownloadCard = ({ orderItem }) => {
  return (
    <div className="col-12" key={orderItem.id}>
      <div className="card activity activity-primary rounded-md shadow p-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="position-relative">
              <img
                src={storage(orderItem.product?.image_path)}
                className="avatar avatar-md-md rounded-md shadow-md"
                alt=""
              />

              {/* <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white">
              {data?.favorite === "Started Following" ? (
                <i className="mdi mdi-check mdi-18px text-success"></i>
              ) : data?.favorite === "Liked by" ? (
                <i className="mdi mdi-check mdi-18px text-success"></i>
              ) : (
                <i className="mdi mdi-check mdi-18px text-warning"></i>
              )}
            </div> */}
            </div>
            <span className="content ms-3">
              <a
                href=""
                onClick={(e) => e.preventDefault()}
                className="text-dark title mb-0 h6 d-block"
              >
                {orderItem.product.name}
              </a>
              <small className="text-muted d-block mt-1">
                <span className="link fw-bold">
                  ${orderItem.unit_price} (x{orderItem.quantity})
                </span>
              </small>

              <small
                className="text-muted d-block mt-1"
                style={{ fontSize: "12px" }}
              >
                Regular License
              </small>
            </span>
          </div>
          <div className="ms-3">
            <a
              href={storage(orderItem.product.zip_file)}
              target="_blank"
              className="btn btn-primary"
            >
              Download
              <i className="ms-1 mdi mdi-download mdi-18px text-white"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadCard;
