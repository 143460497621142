import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Error from "./pages/Error";
import ProductDetails from "./pages/ProductDetails";
import Explore from "./pages/Explore";
import Cart from "./pages/Cart";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Checkout from "./pages/Checkout";
import Authenticated from "./security/authenticated";
import OrderComplete from "./pages/OrderStatus/OrderComplete";
import Downloads from "./pages/Downloads";
import Profile from "./pages/Profile";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Creator from "./pages/Creators";
import CreatorProfile from "./pages/CreatorProfile";
import Terms from "./pages/Terms";

export default function Router() {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="/explore" element={<Explore />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route element={<Authenticated />}>
        <Route path="/cart/checkout" element={<Checkout />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/orders/complete" element={<OrderComplete />} />
        <Route path="/profile" element={<Profile />} />
      </Route>

      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/creators" element={<Creator />} />
      <Route path="/creators/:creatorId" element={<CreatorProfile />} />
      <Route path="/terms-policy" element={<Terms />} />

      <Route path="/products/:productId" element={<ProductDetails />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}
