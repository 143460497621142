import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { bg02 } from "../../components/imageImport";

const Terms = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Start Home   */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg02}) center` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Terms
                </h5>
                {/* <p className="text-white-50 para-desc mx-auto mb-0"></p>   */}
              </div>
            </div>
            {/*end col  */}
          </div>
          {/*end row  */}

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul
                className="breadcrumb breadcrumb-muted mb-0 p-0"
                style={{ backgroundColor: "transparent" }}
              >
                <li className="breadcrumb-item">
                  <Link to="/">Code Bazaar</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Terms
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/*end container  */}
      </section>
      {/*end section  */}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home   */}

      {/* Start Terms & Conditions   */}
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="card shadow border-0 rounded">
                <div className="card-body">
                  <h5 className="card-title">Welcome to Code Bazaar</h5>
                  <p className="text-muted mt-4">
                    These terms and conditions outline the rules and regulations
                    for the use of Code Bazaar's Website, located at
                    www.codebazaar.net
                  </p>
                  <p className="text-muted mt-4">
                    By accessing this website, we assume you accept these terms
                    and conditions. Do not continue to use Code Bazaar if you do
                    not agree to take all of the terms and conditions stated on
                    this page.
                  </p>
                  <p className="text-muted mt-4">
                    The following terminology applies to these Terms and
                    Conditions, Privacy Statement and Disclaimer Notice and all
                    Agreements: "Client", "You" and “Your" refers to you, the
                    person log on this website and compliant to the Company’s
                    terms and conditions. "The Company", "Ourselves", "We",
                    "Our" and "Us", refers to our Company. "Party", "Parties",
                    or "Us", refers to both the Client and ourselves. All terms
                    refer to the offer, acceptance and consideration of payment
                    necessary to undertake the process of our assistance to the
                    Client in the most appropriate manner for the express
                    purpose of meeting the Client’s needs in respect of
                    provision of the Company’s stated services, in accordance
                    with and subject to, prevailing law of Netherlands. Any use
                    of the above terminology or other words in the singular,
                    plural, capitalization and/or he/she or they, are taken as
                    interchangeable and therefore as referring to same.
                  </p>

                  <h5 className="card-title mt-5">Cookies</h5>
                  <p className="text-muted mt-4">
                    We employ the use of cookies. By accessing Code Bazaar, you
                    agreed to use cookies in agreement with the Code Bazaar's
                    Privacy Policy.
                  </p>
                  <p className="text-muted mt-4">
                    Most interactive websites use cookies to let us retrieve the
                    user’s details for each visit. Cookies are used by our
                    website to enable the functionality of certain areas to make
                    it easier for people visiting our website. Some of our
                    affiliate/advertising partners may also use cookies.
                  </p>

                  <h5 className="card-title mt-5">License</h5>
                  <p className="text-muted mt-4">
                    Unless otherwise stated, Code Bazaar and/or its licensors
                    own the intellectual property rights for all material on
                    Code Bazaar. All intellectual property rights are reserved.
                    You may access this from Code Bazaar for your own personal
                    use subjected to restrictions set in these terms and
                    conditions.
                  </p>
                  <p className="text-muted mt-4">
                    You must not:
                    <ul>
                      Republish material from Code Bazaar. <br />
                      Sell, rent or sub-license material from Code Bazaar.{" "}
                      <br />
                      Reproduce, duplicate or copy material from Code Bazaar.{" "}
                      <br />
                      Redistribute content from Code Bazaar.
                    </ul>
                    This Agreement shall begin on the date hereof.
                  </p>
                  <p className="text-muted mt-4">
                    Parts of this website offer an opportunity for users to post
                    and exchange opinions and information in certain areas of
                    the website. Code Bazaar does not filter, edit, publish or
                    review Comments prior to their presence on the website.
                    Comments do not reflect the views and opinions of Code
                    Bazaar, its agents and/or affiliates. Comments reflect the
                    views and opinions of the person who post their views and
                    opinions. To the extent permitted by applicable laws, Code
                    Bazaar shall not be liable for the Comments or for any
                    liability, damages or expenses caused and/or suffered as a
                    result of any use of and/or posting of and/or appearance of
                    the Comments on this website.
                  </p>
                  <p className="text-muted mt-4">
                    Code Bazaar reserves the right to monitor all Comments and
                    to remove any Comments which can be considered
                    inappropriate, offensive or causes breach of these Terms and
                    Conditions
                  </p>
                  <p className="text-muted mt-4">
                    You warrant and represent that:
                    <ul>
                      You are entitled to post the Comments on our website and
                      have all necessary licenses and consents to do so; <br />
                      The Comments do not invade any intellectual property
                      right, including without limitation copyright, patent or
                      trademark of any third party; <br />
                      The Comments do not contain any defamatory, libelous,
                      offensive, indecent or otherwise unlawful material which
                      is an invasion of privacy; <br />
                      The Comments will not be used to solicit or promote
                      business or custom or present commercial activities or
                      unlawful activity.
                    </ul>
                  </p>
                  <p className="text-muted mt-4">
                    You hereby grant Code Bazaar a non-exclusive license to use,
                    reproduce, edit and authorize others to use, reproduce and
                    edit any of your Comments in any and all forms, formats or
                    media.
                  </p>

                  <h5 className="card-title mt-5">Member Responsibilities</h5>
                  <p className="text-muted mt-4">
                    Each Member hereby represents, warrants and agrees to
                    <ul>
                      (a) provide true, accurate, current and complete
                      information about itself and its business references as
                      may be required by Code Bazaar and <br />
                      (b) maintain and promptly amend all information to keep it
                      true, accurate, current and complete. To the extent
                      permitted under applicable laws, each Member hereby grants
                      an irrevocable, perpetual, worldwide and royalty-free,
                      sub-licensable (through multiple tiers) license to Code
                      Bazaar to display and use all information provided by such
                      Member in accordance with the purposes set forth in this
                      Agreement and to exercise the copyright, publicity, and
                      database rights you have in such material or information,
                      in any media now known or not currently known. Information
                      that is protected under data protection laws will only be
                      used and kept in compliance with those laws.
                    </ul>
                  </p>
                  <div className="row">
                    <div className="col-12 text-center mt-4">
                      <Link
                        to="/terms-policy/more"
                        className="btn btn-link primary text-dark"
                      >
                        Read More <i className="uil uil-arrow-right"></i>
                      </Link>
                    </div>
                    {/*end col*/}
                  </div>
                </div>
              </div>
            </div>
            {/*end col  */}
          </div>
          {/*end row  */}
        </div>
        {/*end container  */}
      </section>
      {/*end section  */}
      {/* End Terms & Conditions   */}

      {/* footer */}
      <Footer />
    </>
  );
};

export default Terms;
