import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { bg01, client01 } from "../../components/imageImport";
import { FiCamera } from "react-icons/fi";
import Input from "../../components/input";
import useForm from "../../hooks/use-form";
import useRequest from "../../hooks/use-request";
import { useDispatch, useSelector } from "react-redux";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import { authActions } from "../../store/auth-reducer";

const Profile = () => {
  const navigate = useNavigate();
  const { user, token } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const { inputs, setFields, setOne } = useForm({
    name: user.name,
    password: "",
    password_confirmation: "",
  });

  const { isLoading, validationErros, sendRequest } = useRequest();

  const changeName = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      const response = await sendRequest({
        url: "/customers",
        method: "PATCH",
        token,
        body: {
          name: inputs.name,
        },
      });

      if (response) {
        addAlert(toast.TYPE.SUCCESS, "Your name is updated successfully");
        dispatch(
          authActions.initialize({
            user: {
              ...user,
              name: inputs.name,
            },
          })
        );
      }
    }
  };
  const changePassword = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      const response = await sendRequest({
        url: "/customers/password",
        method: "PATCH",
        token,
        body: {
          password: inputs.password,
          password_confirmation: inputs.password_confirmation,
        },
      });

      if (response) {
        addAlert(toast.TYPE.SUCCESS, "Your password changed successfully");
        setFields({ ...inputs, password: "", password_confirmation: "" });
      }
    }
  };
  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Edit Profile
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Edit your profile
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul
                className="breadcrumb breadcrumb-muted mb-0 p-0"
                style={{ backgroundColor: "transparent" }}
              >
                <li className="breadcrumb-item">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                  >
                    Code Bazaar
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Profile Edit
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}

      {/* Start */}
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7 col-12 order-2 order-md-1 mt-4 pt-2">
              <div className="rounded-md shadow">
                <div className="p-4 border-bottom">
                  <h5 className="mb-0">Edit Profile :</h5>
                </div>

                <div className="p-4">
                  <form onSubmit={changeName} className="profile-edit">
                    <div className="row">
                      <Input
                        containerClass="col-12"
                        label="name"
                        set={setOne}
                        name="name"
                        model={inputs.name}
                        error={validationErros?.name}
                      />
                    </div>
                    {/*end row*/}

                    <div className="row mt-4">
                      <div className="col-12">
                        <button
                          type="submit"
                          id="submit"
                          name="send"
                          className="btn btn-primary rounded-md"
                        >
                          Update Profile Info
                        </button>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                  </form>
                </div>
              </div>

              <div className="rounded-md shadow mt-4">
                <div className="p-4 border-bottom">
                  <h5 className="mb-0">Change Password:</h5>
                </div>
                <div className="p-4">
                  <form onSubmit={changePassword} className="profile-edit">
                    <div className="row g-4">
                      <Input
                        containerClass="col-12"
                        label="password"
                        type="password"
                        set={setOne}
                        name="password"
                        model={inputs.password}
                        error={validationErros?.password}
                      />
                      <Input
                        containerClass="col-12"
                        label="password confirmation"
                        type="password"
                        set={setOne}
                        name="password_confirmation"
                        model={inputs.password_confirmation}
                        error={validationErros?.password_confirmation}
                      />
                    </div>
                    {/*end row*/}

                    <div className="row mt-4">
                      <div className="col-12">
                        <button
                          type="submit"
                          id="submit"
                          name="send"
                          className="btn btn-primary rounded-md"
                        >
                          Update Password
                        </button>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                  </form>
                </div>
              </div>
            </div>
            {/*end col*/}

            <div className="col-lg-4 col-md-5 col-12 order-1 order-md-2 mt-4 pt-2">
              <div className="card ms-lg-5">
                <h1>Hi, {user.name}</h1>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End */}

      {/* footer */}
      <Footer />
    </>
  );
};

export default Profile;
