import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { bg02 } from "../../components/imageImport";
import { useCallback, useEffect, useState } from "react";
import CartProduct from "../../components/cart-product";
import useRequest from "../../hooks/use-request";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Cart = (props) => {
  const [cartProducts, setCartProducts] = useState([]);
  const { isLoading, sendRequest } = useRequest();
  const cartItems = useSelector((store) => store.ui.cartItems);
  const navigate = useNavigate();
  const getCartProducts = useCallback(async () => {
    const response = await sendRequest({
      url: "/cart",
      method: "POST",
      body: cartItems,
    });
    if (response) {
      setCartProducts(response);
    }
  }, [cartItems, sendRequest]);

  const dispatch = useDispatch();
  useEffect(() => {
    getCartProducts();
  }, [getCartProducts]);
  return (
    <div className="">
      <Navbar />
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg02}) center bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Cart
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Please check all the digital product in your cart
                </p>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul
                className="breadcrumb breadcrumb-muted mb-0 p-0"
                // style="background-color: transparent;"
              >
                <li className="breadcrumb-item">
                  <Link to="/">Code Bazaar</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Cart
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* CONTENT */}
      <section class="section">
        <div class="container">
          {cartProducts?.length == 0 && !isLoading ? (
            <div class="col-12 text-muted">Oops!, No producs in your cart</div>
          ) : (
            <div class="row">
              <div class="col-lg-7 col-md-7">
                <div class="me-lg-4">
                  <div class="row g-4">
                    {isLoading && <p>Loading...</p>}
                    {cartProducts?.map((cartProduct) => (
                      <div class="col-12" key={cartProduct.productId}>
                        <CartProduct
                          cartProduct={cartProduct}
                          dispatch={dispatch}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="sticky-bar">
                  <div class="p-4 rounded-md shadow ">
                    <h5 class="mb-2">Order Summary</h5>
                    <div className="py-3 border-top">
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold small"> Subtotal:</p>
                        <p className="text-primary">
                          $
                          {cartProducts
                            .reduce(
                              (prev, cartProduct) =>
                                prev +
                                cartProduct.quantity *
                                  cartProduct.product.price,
                              0
                            )
                            .toFixed(2)}{" "}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold small"> Service free:</p>
                        <p className="text-primary"> $0.00 </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold small"> Total:</p>
                        <p className="text-primary mb-0">
                          {" "}
                          $
                          {cartProducts
                            .reduce(
                              (prev, cartProduct) =>
                                prev +
                                cartProduct.quantity *
                                  cartProduct.product.price,
                              0
                            )
                            .toFixed(2)}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <button
                        className="btn btn-pills btn-primary w-100"
                        data-bs-target="#buyNftSuccess"
                        data-bs-toggle="modal"
                        onClick={() => navigate("/cart/checkout")}
                      >
                        <i className="mdi mdi-cart fs-5 me-2"></i> Checkout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Cart;
