import { forwardRef } from "react";
import { Link } from "react-router-dom";

const CreatorCard = forwardRef(({ creator }, ref) => {
  return (
    <div className="card creators creators-two creator-primary rounded-md shadow overflow-hidden mx-2 my-3">
      <div
        className="py-5"
        style={{
          background: `url(${process.env.REACT_APP_BACKEND_STORAGE}${creator.cover_path})`,
        }}
      ></div>
      <div className="position-relative mt-n5">
        <img
          src={`${process.env.REACT_APP_BACKEND_STORAGE}${creator.profile_path}`}
          className="avatar avatar-md-md rounded-pill shadow-sm bg-light img-thumbnail mx-auto d-block"
          alt=""
        />

        <div className="content text-center pt-2 p-4">
          <Link
            to={`/creators/${creator.id}`}
            className="text-dark h6 name d-block mb-0"
          >
            {creator.name}
          </Link>
          <small className="text-muted">{creator.email}</small>
        </div>
      </div>
    </div>
  );
});

export default CreatorCard;
