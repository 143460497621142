import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { tns } from "tiny-slider/src/tiny-slider";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import useRequest from "../../hooks/use-request";
import CreatorCard from "../../components/creator-card";
import ProductCard from "../../components/product-card";
import PageLoading from "../../components/PageLoading";
import SearchBar from "../../components/search-bar";
import homeImage from "../../assets/images/home.png";
const Home = () => {
  const navigate = useNavigate();

  const { isLoading, sendRequest } = useRequest();

  const [products, setProducts] = useState([]);
  const [creators, setCreators] = useState([]);

  const getData = useCallback(async () => {
    const response = await sendRequest({
      url: "/home",
    });
    if (response) {
      setCreators(response.top_creators ?? []);
      setProducts(response.recent_products ?? []);
    }
  }, [sendRequest]);

  useEffect(() => {
    if (
      creators.length > 0 &&
      document.getElementsByClassName("tiny-five-item-nav-arrow").length > 0
    ) {
      var slider6 = tns({
        container: ".tiny-five-item-nav-arrow",
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: [
          '<i class="mdi mdi-chevron-left "></i>',
          '<i class="mdi mdi-chevron-right"></i>',
        ],
        nav: false,
        speed: 400,
        gutter: 10,
        responsive: {
          992: {
            items: 5,
          },

          767: {
            items: 3,
          },

          320: {
            items: 1,
          },
        },
      });
    }
  }, [creators]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      {isLoading && <PageLoading />}
      {/* Navbar */}
      <Navbar />

      {/* Start Home */}
      <section className="bg-half-100 d-table w-100 pb-0">
        <div className="container position-relative" style={{ zIndex: 1 }}>
          <div className="bg-half-100 rounded-md shadow-sm position-relative overflow-hidden">
            <div
              className="bg-video-wrapper"
              style={{
                backgroundImage: `url(${homeImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {/* <img
                src={homeImage}
                style={{
                  objectFit: "fill",
                  objectPosition: "left",
                }}
              /> */}
              {/* <iframe src="https://player.vimeo.com/video/502163294?background=1&autoplay=1&loop=1&byline=0&title=0"></iframe> */}
              {/*Note: Vimeo Embed Background Video*/}

              {/* <iframe src="https://www.youtube.com/embed/yba7hPeTSjk?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1"></iframe> */}
              {/*Note: Youtube Embed Background Video*/}
            </div>
            <div className="bg-overlay bg-linear-gradient-2"></div>
            <div className="row justify-content-center my-5">
              <div className="col-12">
                <div className="title-heading text-center px-4">
                  <h4 className="display-6 text-white title-dark fw-medium mb-3">
                    The way to Find <br /> any{" "}
                    <span className="text-gradient-primary">Digital</span>{" "}
                    Assets
                  </h4>
                  <p className="text-white title-dark mb-0">
                    Discover Limited-Edition Digital Assets.
                  </p>
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
        </div>
        {/*end container*/}
      </section>
      {/* End Home */}

      <section className="section">
        {/* SEARCH */}
        <SearchBar />
        {/* ITEMS */}
        <div className="container mt-4 pt-2 mt-lg-0 pt-lg-0">
          <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4">
            {products?.map((product) => (
              <ProductCard product={product} />
            ))}
          </div>
        </div>

        {/* TEAM */}
        <div className="container mt-100 mt-60">
          <div className="row align-items-end mb-4 pb-2">
            <div className="col-md-8">
              <div className="section-title">
                <h4 className="title mb-2">Best Developers & Sellers</h4>
                <p className="text-muted mb-0">
                  Top Developers & Sellers of the Year
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-3">
              <div className="tiny-five-item-nav-arrow">
                {creators?.map((creator, index) => (
                  <div className="tiny-slide" key={creator.id}>
                    <CreatorCard creator={creator} />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="text-center d-md-none d-block">
                <a
                  href="/creators"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/creators");
                  }}
                  className="btn btn-link primary text-dark"
                >
                  See More <i className="uil uil-arrow-right"></i>
                </a>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
