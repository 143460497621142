import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { bg01 } from "../../components/imageImport";
import useRequest from "../../hooks/use-request";
import CreatorCard from "../../components/creator-card";

const Creators = () => {
  const [creators, setCreators] = useState([]);
  const { isLoading, sendRequest } = useRequest();

  const getCreators = useCallback(async () => {
    let response = await sendRequest({
      url: "/creators",
    });

    if (response) {
      setCreators(response);
    }
  }, [sendRequest]);
  useEffect(() => {
    getCreators();
  }, [getCreators]);
  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/*- Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Creators
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  All Featured Creators
                </p>
              </div>
            </div>
            {/*-end col*/}
          </div>
          {/*-end row*/}

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul
                className="breadcrumb breadcrumb-muted mb-0 p-0"
                style={{ backgroundColor: "transparent" }}
              >
                <li className="breadcrumb-item">
                  <Link to="/">Code Bazaar</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Creators
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/*-end container*/}
      </section>
      {/*-end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/*- End Home */}

      {/*- Start Section */}
      <section className="section">
        <div className="container">
          <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-4 justify-content-center">
            {creators?.map((creator, index) => {
              return (
                <div className="col" key={creator.id}>
                  <CreatorCard creator={creator} />
                </div>
              );
            })}
            {/*-end col*/}
          </div>
          {/*-end row*/}
          {/*-end row*/}
        </div>
        {/*-end container*/}
      </section>
      {/*-end section*/}
      {/*- End Section */}
      {/* footer */}
      <Footer />
    </>
  );
};

export default Creators;
