import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import styles from "./index.module.css";
import useRequest from "../../hooks/use-request";
import { storage } from "../../utils/methods";
import Loading from "../../components/PageLoading";
import ProductCard from "../../components/product-card";
import { uiAction } from "../../store/ui-reducer";
import { useDispatch } from "react-redux";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { sendRequest, isLoading } = useRequest();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [related, setRelated] = useState([]);

  const getProduct = useCallback(async () => {
    const response = await sendRequest({
      url: "/products/" + productId,
    });
    if (response) {
      setProduct(response.product);
      setRelated(response.related);
    }
  }, [productId, sendRequest]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  const dispatch = useDispatch();
  return (
    <>
      {/* Navbar */}
      <Navbar />
      {isLoading && <Loading />}
      {/* Start */}
      {/* <section className="bg-item-detail d-table w-100"> */}
      {product && (
        <section className="pt-5 mt-5 d-table w-100">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="sticky-bar">
                  <img
                    src={storage(product.image_path)}
                    className="img-fluid rounded-md shadow"
                    alt=""
                  />
                </div>
              </div>

              <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="ms-lg-5">
                  <div className="title-heading">
                    <h4 className="h3 fw-bold mb-0">{product.name}</h4>
                  </div>

                  <div className="row">
                    <div class="col-md-6 mt-4 pt-2">
                      <h6>Current Price</h6>
                      <h4 class="mb-0">${product.price}</h4>
                      {/* <small class="mb-0 text-muted">$450.48USD</small> */}
                    </div>
                    <div class="col-md-6 mt-4 pt-2">
                      <h6>Quantity</h6>
                      <div className={styles.quantityContainer}>
                        <span
                          onClick={() =>
                            setQuantity((prev) => (prev > 1 ? prev - 1 : prev))
                          }
                        >
                          -
                        </span>
                        <h4 class="mb-0">{quantity}</h4>
                        <span
                          onClick={() =>
                            setQuantity((prev) => (prev < 10 ? prev + 1 : prev))
                          }
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div className="col-12 mt-4 pt-2">
                      <button
                        href="#"
                        className="btn btn-l btn-pills btn-primary me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#NftBid"
                        onClick={() =>
                          dispatch(
                            uiAction.addToCart({
                              productId: product.id,
                              quantity: quantity,
                            })
                          )
                        }
                      >
                        <i className="mdi mdi-cart fs-5 me-2"></i> Add to Cart
                      </button>
                      <button
                        onClick={() =>
                          navigate({
                            pathname: "/checkout",
                            search: `?productId=${product.id}&quantity=${quantity}`,
                          })
                        }
                        className="btn btn-l btn-pills btn-primary"
                      >
                        Buy Now
                        <i className="ms-1 mdi mdi-arrow-right-thin-circle-outline fs-5"></i>
                      </button>
                    </div>
                  </div>

                  <div className="row mt-4 pt-2">
                    <div className="col-12">
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="detailItem"
                          role="tabpanel"
                          aria-labelledby="detail-tab"
                        >
                          {product.description.split("\n").map((para) => (
                            <p className="text-muted">
                              {para.replace("\n", "")}
                            </p>
                          ))}
                          <h6>Creator</h6>

                          <div className="creators creator-primary d-flex align-items-center">
                            <div className="position-relative">
                              <img
                                src={storage(product.creator?.profile_path)}
                                className="avatar avatar-md-sm shadow-md rounded-pill"
                                alt=""
                              />
                              <span className="verified text-primary">
                                <i className="mdi mdi-check-decagram"></i>
                              </span>
                            </div>

                            <div className="ms-3">
                              <h6 className="mb-0">
                                <a
                                  href="/creators"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/creators");
                                  }}
                                  className="text-dark name"
                                >
                                  {product.creator?.name}
                                </a>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}

          <div className="container mt-100 mt-60 mb-60">
            <div className="row justify-content-center">
              <div className="col">
                <div className="section-title text-center mb-4 pb-2">
                  <h4 className="title mb-4">Related Products</h4>
                  <p className="text-muted para-desc mb-0 mx-auto">
                    Discover thousands of easy to customize plugins, code
                    snippets, themes & templates made by our creators.
                  </p>
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}

            <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
              {related?.map((data) => {
                return <ProductCard product={data} />;
              })}
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
        </section>
      )}
      {/*end section*/}
      {/* End */}

      {/* Buy Now NFt Modal */}
      {/* footer */}
      <Footer />
    </>
  );
};

export default ProductDetails;
