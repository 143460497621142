import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { addAlert } from "../utils/alert";

export const uiSlice = createSlice({
  name: "alert",
  initialState: {
    categories: [],
    cartItems: [],
    favItems: [],
  },
  reducers: {
    initialize: (state, action) => {
      const fav = JSON.parse(localStorage.getItem("favItems") ?? "[]");
      const cart = JSON.parse(localStorage.getItem("cartItems") ?? "[]");

      return {
        ...state,
        ...action.payload,
        cartItems: cart ?? [],
        favItems: fav ?? [],
      };
    },
    toggleFav: (state, action) => {
      const { id } = action.payload;
      let favObj = JSON.parse(localStorage.getItem("favItems") ?? "[]");
      if (favObj.find((favId) => favId == id)) {
        favObj = favObj.filter((i) => i != id);
      } else {
        favObj.push(id);
      }
      localStorage.setItem("favItems", JSON.stringify(favObj));
      return {
        ...state,
        favItems: favObj,
      };
    },
    addToCart: (state, action) => {
      const { productId, quantity } = action.payload;
      let cartObj = JSON.parse(localStorage.getItem("cartItems") ?? "[]");
      cartObj.push({
        id: Math.random().toString(16).slice(2),
        productId: productId,
        quantity,
      });
      console.log(cartObj);

      localStorage.setItem("cartItems", JSON.stringify(cartObj));
      addAlert(toast.TYPE.SUCCESS, "Item added to cart successfully");
      return {
        ...state,
        cartItems: cartObj,
      };
    },
    emptyCart: (state, action) => {
      localStorage.removeItem("cartItems");
      return {
        ...state,
        cartItems: [],
      };
    },
    removeFromCart: (state, action) => {
      const { id } = action.payload;
      let cartObj = JSON.parse(
        localStorage.getItem("cartItems") ?? "[]"
      ).filter((item) => item.id != id);
      localStorage.setItem("cartItems", JSON.stringify(cartObj));
      addAlert(toast.TYPE.SUCCESS, "Item removed from cart successfully");

      return {
        ...state,
        cartItems: cartObj,
      };
    },
    updateCart: (state, action) => {
      const { cartItems } = action.payload;
      addAlert(toast.TYPE.SUCCESS, "cart updated successfully");
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      return {
        ...state,
        cartItems: cartItems.map((c) => ({
          ...c,
          id: Math.random().toString(16).slice(2),
        })),
      };
    },
  },
});

export const uiAction = uiSlice.actions;

export default uiSlice.reducer;
