import React from "react";
import { useNavigate } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import BackToTop from "../BackToTop";
import { MetaMask_Fox, playStore, app, iconLogo } from "../imageImport";
import { useSelector } from "react-redux";

const Footer = () => {
  const navigate = useNavigate();
  const categories = useSelector((store) => store.ui.categories);
  return (
    <>
      <footer className="bg-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-py-60 footer-border">
                <div className="row">
                  <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                    <h5 className="footer-head">Code Bazaar</h5>
                    <p className="para-desc mb-0 mt-4">
                      Discover Exclusive Creative Assets by Developers all
                      around the world. Buy & Sell Thousands of Codes, Scripts &
                      Plugins for Every Website & Mobile Applications Build.
                      Whether it's Website templates, website themes, photos,
                      video, logo or audio, there's every chance you’ll spot
                      something from Code Bazaar today, from a Templates to a
                      Source Codes.
                    </p>
                  </div>
                  {/*end col*/}
                  <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"></div>
                  <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h5 className="footer-head">Explore</h5>
                    <ul className="list-unstyled footer-list mt-4">
                      <li>
                        <a
                          href="/explore"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/explore");
                          }}
                          className="text-foot"
                        >
                          <i className="uil uil-angle-right-b me-1"></i> Explore
                        </a>
                      </li>
                      {categories?.map((category) => (
                        <li>
                          <a
                            href={`/explore?category=${category.id}`}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/explore?category=${category.id}`);
                            }}
                            className="text-foot"
                          >
                            <i className="uil uil-angle-right-b me-1"></i>{" "}
                            {category.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/*end col*/}

                  <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h5 className="footer-head">Community</h5>
                    <ul className="list-unstyled footer-list mt-4">
                      <li>
                        <a
                          href="/aboutus"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/aboutus");
                          }}
                          className="text-foot"
                        >
                          <i className="uil uil-angle-right-b me-1"></i> About
                          Us
                        </a>
                      </li>
                      <li>
                        <a
                          href="/creators"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/creators");
                          }}
                          className="text-foot"
                        >
                          <i className="uil uil-angle-right-b me-1"></i>{" "}
                          Creators
                        </a>
                      </li>
                      <li>
                        <a
                          href="/contactus"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/contactus");
                          }}
                          className="text-foot"
                        >
                          <i className="uil uil-angle-right-b me-1"></i> Contact
                        </a>
                      </li>
                      <li>
                        <a
                          href="/terms-policy"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/terms-policy");
                          }}
                          className="text-foot"
                        >
                          <i className="uil uil-angle-right-b me-1"></i> Terms &
                          Policies
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/*end col*/}

                  {/* <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h5 className="footer-head">Newsletter</h5>
                    <p className="mt-4">
                      Sign up and receive the latest tips via email.
                    </p>
                    <form>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="foot-subscribe mb-3">
                            <label className="form-label">
                              Write your email{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <FiMail className="fea icon-sm icons" />
                              <input
                                type="email"
                                name="email"
                                id="emailsubscribe"
                                className="form-control ps-5 rounded"
                                placeholder="Your email : "
                                required
                                style={{ height: 46 }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submitsubscribe"
                              name="send"
                              className="btn btn-soft-primary"
                              value="Subscribe"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div> */}
                  {/*end col*/}
                </div>
                {/*end row*/}
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

        <div className="footer-py-30 footer-bar">
          <div className="container text-center">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="text-sm-start">
                  <p className="mb-0">
                    Copyright ©{" "}
                    <script>document.write(new Date().getFullYear())</script>{" "}
                    2014-2023 Code Bazaar. All Rights Reserved.
                  </p>
                </div>
              </div>
              {/*end col*/}

              <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <ul className="list-unstyled footer-list text-sm-end mb-0">
                  <p className="mb-0">
                    Trademarks and Brands are the Property of their Respective
                    Owners
                  </p>
                </ul>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
        </div>
      </footer>
      {/*end footer*/}

      {/* Back to top */}
      <BackToTop />
    </>
  );
};

export default Footer;
