import React, { forwardRef } from "react";

import styles from "./input.module.css";
const Input = forwardRef(
  (
    {
      size,
      label,
      icon,
      containerClass,
      type,
      error,
      set,
      model,
      name,
      placeholder,
      options,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`${styles.inputContainer} ${containerClass}`}>
        <label className="form-label fw-bold text-capitalize">{label}:</label>
        {options ? (
          <select
            onChange={(e) => {
              set(name, e.target.value);
            }}
            value={model}
            ref={ref}
            {...props}
            className="form-control"
            style={{ borderColor: error && "#e23838" }}
          >
            <option value="">Select a {label ?? name}</option>
            {options.map((op) => (
              <option value={op.value}>{op.label}</option>
            ))}
          </select>
        ) : (
          <input
            onChange={(e) => {
              set(name, e.target.value);
            }}
            value={model}
            ref={ref}
            {...props}
            type={type ?? "text"}
            placeholder={placeholder ?? `Enter your ${label ?? name}`}
            className="form-control"
            style={{ borderColor: error && "#e23838" }}
          />
        )}
        {error && <span>{error}</span>}
      </div>
    );
  }
);

export default Input;
