import { toast } from "react-toastify";

export const addAlert = (type, message, title = null) => {
  toast(message, {
    type: type,
    position: "bottom-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: ({ closeToast }) => (
      <span
        class="align-items-center justify-content-center "
        style={{ display: "flex" }}
        onClick={closeToast}
        role="button"
      >
        <i className="mdi mdi-18px mdi-close mb-0"></i>
      </span>
    ),
  });
};
