import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { whiteLogo } from "../../components/imageImport";
import useRequest from "../../hooks/use-request";
import useForm from "../../hooks/use-form";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-reducer";
import Input from "../../components/input";
import PageLoading from "../../components/PageLoading";
import Button from "../../components/Button";

const Login = () => {
  const { setOne, inputs } = useForm({
    email: "",
    password: "",
  });
  const { isLoading, errors, sendRequest, validationErros } = useRequest();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      const response = await sendRequest({
        url: "/login",
        method: "POST",
        body: inputs,
      });
      if (response) {
        dispatch(authActions.onLoggedIn(response));
        navigate("/");
      }
    }
  };
  return (
    <>
      {/*  Hero Start  */}
      <section className="position-relative">
        <div className="bg-video-wrapper">
          {/* <iframe src="https://player.vimeo.com/video/502163294?background=1&autoplay=1&loop=1&byline=0&title=0"></iframe> */}
          {/* Note: Vimeo Embed Background Video */}

          {/* <iframe src="https://www.youtube.com/embed/wAmbDCJocJM?autoplay=1&loop=1&mute=1"></iframe> */}
          {/* Note: Youtube Embed Background Video */}
        </div>
        <div
          className="bg-overlay"
          style={{
            background: "rgb(76,70,212)",
            background:
              "linear-gradient(90deg, rgba(76,70,212,1) 0%, rgba(0,212,255,1) 100%)",
          }}
        ></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="d-flex flex-column min-vh-100 p-4">
                {/*  Start Logo  */}
                <div className="text-center">
                  <Link to="/">
                    <img src={whiteLogo} alt="" style={{ width: "250px" }} />
                  </Link>
                </div>
                {/*  End Logo  */}

                {/*  Start Content  */}
                <div className="title-heading text-center my-auto">
                  <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                    <form onSubmit={onFormSubmit}>
                      <h5 className="mb-4">Login</h5>

                      <div className="row g-3">
                        {errors.length > 0 && (
                          <div
                            className="bg-soft-danger px-3 rounded shadow"
                            style={{ textAlign: "left" }}
                          >
                            <div className="d-flex align-items-center">
                              <i className="uil uil-exclamation-circle h2 mb-0 me-2"></i>
                              <div className="flex-1">
                                {/* <h6 className="mb-0">
                                This creator is not verified
                              </h6> */}
                                <small className="mb-0">
                                  Invalid Credentials
                                </small>
                              </div>
                            </div>
                          </div>
                        )}
                        <Input
                          containerClass="col-lg-12"
                          label="email"
                          set={setOne}
                          name="email"
                          model={inputs.email}
                          error={validationErros?.email}
                        />
                        <Input
                          containerClass="col-lg-12"
                          name="password"
                          set={setOne}
                          model={inputs.password}
                          error={validationErros?.password}
                          label="password"
                          type={"password"}
                        />

                        {/* end col */}
                        <div className="col-lg-12 mt-5">
                          <Button isLoading={isLoading}>Sign In</Button>
                        </div>
                        {/* end col */}

                        <div className="col-12 text-center mt-4">
                          <small>
                            <span className="text-muted me-2">
                              Don't have an account ?
                            </span>{" "}
                            <Link
                              to={"/register"}
                              className="text-dark fw-bold"
                            >
                              Register
                            </Link>
                          </small>
                        </div>
                        {/* end col */}
                      </div>
                      {/* end row */}
                    </form>
                  </div>
                </div>
                {/*  End Content  */}

                {/*  Start Footer  */}
                <div className="text-center">
                  <small className="mb-0 text-light title-dark">
                    <p className="mb-0">
                      Copyright ©{" "}
                      <script>document.write(new Date().getFullYear())</script>{" "}
                      2014-2023 Code Bazaar.
                    </p>
                  </small>
                </div>
                {/*  End Footer  */}
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      {/*  Hero End  */}
    </>
  );
};

export default Login;
