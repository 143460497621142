import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./assets/js/my";
import App from "./App";

// import "./assets/css/bootstrap.min.css";
// import "./assets/css/newmaterialdesignicons.min.css";
// import "./assets/css/materialdesignicons.min.css";
// import "./assets/css/tobii.min.css";
// import "./assets/css/tiny-slider.css";
// import "./assets/css/style.min.css";
// import "./assets/css/dashboard-style.css";
// import "./assets/css/datepicker.min.css";
// import "./assets/js/bootstrap.bundle.min.js";
// import "./assets/js/datepicker.min.js";
// import "./assets/js/tiny-slider.js";
// import "./assets/js/shuffle.min.js";
// import "./assets/js/feather.min.js";
// import "./assets/js/feather.min.js.map";
// import "./assets/js/app.js";
// import "./assets/js/tobii.min.js";
// import "./assets/js/plugins.init.js";
// import "./assets/css/style.min.css";
function windowScroll() {
  const navbar = document.getElementById("topnav");
  if (navbar != null) {
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("nav-sticky");
    } else {
      navbar.classList.remove("nav-sticky");
    }
  }
}

window.addEventListener("scroll", (ev) => {
  ev.preventDefault();
  console.log("ON SCROLL");
  windowScroll();
});
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
