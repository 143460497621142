import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  Link,
} from "react-router-dom";
import { toast } from "react-toastify";
import useRequest from "../../hooks/use-request";
import { authActions } from "../../store/auth-reducer";
import { addAlert } from "../../utils/alert";
import { paramsToObject } from "../../utils/methods";
import { character, client01, lightLogo, logoDark } from "../imageImport";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const categories = useSelector((store) => store.ui.categories);
  const user = useSelector((store) => store.auth.user);
  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);
  const token = useSelector((store) => store.auth.token);

  const url = useMemo(() => location?.pathname === "/blog-detail", []);

  const [mobile, setMobile] = useState([]);
  const { isLoading, sendRequest } = useRequest();

  const pages = [
    "/explore",
    "/cart",
    "/downloads",
    "/profile",
    "/contactus",
    "/aboutus",
    "/creators",
    "/terms-policy",
  ];
  const isDarkThemePage = pages.includes(location?.pathname);

  let [searchParams, setSearchParams] = useSearchParams();
  const [keywords, setKeywords] = useState(
    paramsToObject(searchParams).keywords
  );

  useEffect(() => {
    document.querySelector("body > iframe")?.remove();
  });
  const onSearch = (e) => {
    e.preventDefault();
    if (keywords.trim().length > 0) {
      navigate({
        pathname: "/explore",
        search:
          "?" +
          new URLSearchParams({
            ...paramsToObject(searchParams),
            keywords: keywords,
          }).toString(),
      });
    }
  };

  const mobileHandler = (e, panel) => {
    e.preventDefault();
    const dataIndex = mobile?.findIndex((data) => data === panel);
    if (dataIndex === -1) {
      setMobile([...mobile, panel]);
    } else {
      const updateRecord = mobile?.filter((data, index) => index !== dataIndex);
      setMobile(updateRecord);
    }
  };

  const closeModal = () => {
    //   metamask modal
    const modal = document.getElementById("modal-metamask");

    modal.classList.remove("show");
    modal.style.display = "none";
  };

  const getClosest = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  };

  const activateMenu = () => {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    console.log(menuItems);
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          menuItems[idx].classList.add("active");
          matchingMenuItem = menuItems[idx];
        } else {
          menuItems[idx].classList.remove("active");
        }
      }

      if (matchingMenuItem) {
        var immediateParent = getClosest(matchingMenuItem, "li");
        if (immediateParent) {
          immediateParent.classList.add("active");
        }

        var parent = getClosest(matchingMenuItem, ".parent-menu-item");
        if (parent) {
          parent.classList.add("active");
          var parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }
          var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          var parentOfParent = getClosest(
            matchingMenuItem,
            ".parent-parent-menu-item"
          );
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
    }
  };

  const toggleMenu = () => {
    document.getElementById("isToggle").classList.toggle("open");
    var isOpen = document.getElementById("navigation");
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  };

  const onLogout = async (e) => {
    e.preventDefault();
    const response = await sendRequest({
      url: "/logout",
      method: "POST",
      token: token,
    });

    if (response) {
      dispatch(authActions.onLogout());
      addAlert(toast.TYPE.INFO, "Logout Successfully");
    }
  };
  return (
    <>
      {/* Navbar STart */}
      <header
        id="topnav"
        className={`defaultscroll sticky ${url && "gradient"}`}
      >
        <div className="container">
          {/* Logo Start*/}
          <Link className="logo" to="/">
            <div className="pb-4">
              <img
                src={logoDark}
                height="26"
                className={!isDarkThemePage ? "logo-light-mode" : "l-dark"}
                alt=""
              />
              <img
                src={lightLogo}
                height="26"
                className={!isDarkThemePage ? "logo-dark-mode" : "l-light"}
                alt=""
              />
            </div>
            {/* {!becomeUrl && (
              <img
                src={lightLogo}
                height="26"
                className="logo-dark-mode"
                alt=""
              />
            )} */}
          </Link>
          {/* Logo end*/}

          {/* Mobile */}
          <div className="menu-extras">
            <div className="menu-item">
              {/* Mobile menu toggle*/}
              <a
                className="navbar-toggle"
                id="isToggle"
                onClick={(e) => {
                  e.preventDefault();
                  toggleMenu();
                }}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
              {/* End mobile menu toggle*/}
            </div>
          </div>
          {/* Mobile */}

          {/*Login button Start*/}
          <ul className="buy-button list-inline mb-0">
            {/* Search Button */}
            <li className="list-inline-item mb-0 me-1">
              <div className="dropdown">
                <button
                  type="button"
                  className="btn dropdown-toggle p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {!isDarkThemePage ? (
                    <i className="uil uil-search text-dark fs-6 align-middle btn btn-icon btn-pills btn-primary"></i>
                  ) : (
                    <>
                      <i className="uil uil-search text-white title-dark btn-icon-light fs-5 align-middle"></i>
                      <i className="uil uil-search text-dark btn-icon-dark fs-5 align-middle"></i>
                    </>
                  )}
                </button>
                <div
                  className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-0"
                  style={{ width: 300 }}
                >
                  <div className="search-bar">
                    <div id="itemSearch" className="menu-search mb-0">
                      <form
                        onSubmit={onSearch}
                        role="search"
                        method="get"
                        id="searchItemform"
                        className="searchform"
                      >
                        <input
                          onChange={(e) => setKeywords(e.target.value)}
                          value={keywords}
                          type="text"
                          className="form-control border rounded"
                          name="s"
                          id="searchItem"
                          placeholder="Search..."
                        />
                        <input
                          type="submit"
                          id="searchItemsubmit"
                          value="Search"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            {/* Cart Button */}
            <li className="list-inline-item mb-0 me-1">
              <Link to={"/cart"}>
                {!isDarkThemePage ? (
                  <span
                    id="connectWallet"
                    className="btn btn-icon btn-pills btn-primary"
                  >
                    <i className="uil uil-shopping-cart-alt fs-6"></i>
                  </span>
                ) : (
                  <p id="connectWallet">
                    <span className="btn-icon-dark">
                      <span className="btn btn-icon btn-pills btn-primary">
                        <i className="uil uil-shopping-cart-alt fs-6"></i>
                      </span>
                    </span>
                    <span className="btn-icon-light">
                      <span className="btn btn-icon btn-pills btn-light">
                        <i className="uil uil-shopping-cart-alt fs-6"></i>
                      </span>
                    </span>
                  </p>
                )}
              </Link>
            </li>

            {/* User Button */}
            <li className="list-inline-item mb-0 ">
              <div className="dropdown dropdown-primary">
                <button
                  type="button"
                  className="btn btn-pills dropdown-toggle p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {!isDarkThemePage ? (
                    <span className="btn btn-icon btn-pills btn-primary">
                      <i className="uil uil-user fs-6"></i>
                    </span>
                  ) : (
                    <p style={{ marginBottom: 0 }}>
                      <span className="btn-icon-dark">
                        <span className="btn btn-icon btn-pills btn-primary">
                          <i className="uil uil-user fs-6"></i>
                        </span>
                      </span>
                      <span className="btn-icon-light">
                        <span className="btn btn-icon btn-pills btn-light">
                          <i className="uil uil-user fs-6"></i>
                        </span>
                      </span>
                    </p>
                  )}
                </button>
                <div
                  className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 pb-3 pt-0 overflow-hidden rounded"
                  style={{ minWidth: 200 }}
                >
                  <h6 className="text-dark fw-bold mb-0 ms-1 px-3 mt-2 text-capitalize">
                    Hi, {isAuthenticated ? user?.name : "Guest"}
                  </h6>
                  <div className="mt-2">
                    {isAuthenticated ? (
                      <>
                        <Link
                          className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                          to="/profile"
                        >
                          <span className="mb-0 d-inline-block me-1">
                            <i className="uil uil-user align-middle h6 mb-0 me-1"></i>
                          </span>{" "}
                          Profile
                        </Link>
                        <Link
                          className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                          to="/downloads"
                        >
                          <span className="mb-0 d-inline-block me-1">
                            <i className="uil uil-import  align-middle h6 mb-0 me-1"></i>
                          </span>{" "}
                          Downloads
                        </Link>
                        <div className="dropdown-divider border-top"></div>
                        <div
                          style={{ cursor: "pointer" }}
                          className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                          href="/lock-screen"
                          onClick={onLogout}
                        >
                          <span className="mb-0 d-inline-block me-1">
                            <i className="uil uil-sign-out-alt align-middle h6 mb-0 me-1"></i>
                          </span>{" "}
                          Logout
                        </div>
                      </>
                    ) : (
                      <>
                        <Link
                          className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                          to="/login"
                        >
                          <span className="mb-0 d-inline-block me-1">
                            <i className="uil uil-sign-in-alt align-middle h6 mb-0 me-1"></i>
                          </span>{" "}
                          Login
                        </Link>
                        <Link
                          className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                          to="/register"
                        >
                          <span className="mb-0 d-inline-block me-1">
                            <i className="uil uil-user-plus  align-middle h6 mb-0 me-1"></i>
                          </span>{" "}
                          Register
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </li>
          </ul>
          {/*Login button End*/}

          <div id="navigation">
            {/* Navigation Menu*/}
            <ul
              className={`navigation-menu nav-left ${
                isDarkThemePage && "nav-light"
              }`}
            >
              <li>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                      activateMenu();
                    }, 1000);
                    navigate("/");
                  }}
                  className="sub-menu-item"
                >
                  Home
                </a>
              </li>

              <li className="has-submenu parent-parent-menu-item">
                <a href="" onClick={(e) => mobileHandler(e, "explores")}>
                  Explore
                </a>
                <span className="menu-arrow"></span>
                <ul
                  className={`submenu ${
                    mobile.includes("explores") ? "open" : ""
                  }`}
                >
                  <li>
                    <a
                      href="/explore"
                      onClick={(e) => {
                        e.preventDefault();
                        setTimeout(() => {
                          activateMenu();
                        }, 1000);
                        navigate("/explore");
                      }}
                      className="sub-menu-item"
                    >
                      {" "}
                      All Items
                    </a>
                  </li>
                  {categories?.slice(2).map((category) => (
                    <li>
                      <a
                        href={`/explore?category=${category.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setTimeout(() => {
                            activateMenu();
                          }, 1000);
                          navigate(`/explore?category=${category.id}`);
                        }}
                        className="sub-menu-item"
                      >
                        {category.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>

              {categories?.slice(0, 2).map((category) => (
                <li>
                  <a
                    href={`/explore?category=${category.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setTimeout(() => {
                        activateMenu();
                      }, 1000);
                      navigate(`/explore?category=${category.id}`);
                    }}
                    className="sub-menu-item"
                  >
                    {category.name}
                  </a>
                </li>
              ))}

              <li>
                <a
                  href="/creators"
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                      activateMenu();
                    }, 1000);
                    navigate("/creators");
                  }}
                  className="sub-menu-item"
                >
                  Creators
                </a>
              </li>

              <li>
                <a
                  href="/aboutus"
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                      activateMenu();
                    }, 1000);
                    navigate("/aboutus");
                  }}
                  className="sub-menu-item"
                >
                  About
                </a>
              </li>

              <li>
                <a
                  href="/contactus"
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                      activateMenu();
                    }, 1000);
                    navigate("/contactus");
                  }}
                  className="sub-menu-item"
                >
                  Contact
                </a>
              </li>
            </ul>
            {/*end navigation menu*/}
          </div>
          {/*end navigation*/}
        </div>
        {/*end container*/}
      </header>
      {/*end header*/}
      {/* Navbar End */}
    </>
  );
};

export default Navbar;
