import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { bg02 } from "../../components/imageImport";
import { useCallback, useEffect, useRef, useState } from "react";
import CartProduct from "../../components/cart-product";
import Button from "../../components/Button";

import useRequest from "../../hooks/use-request";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useForm from "../../hooks/use-form";
import Input from "../../components/input";
import { countryList } from "../../utils/statics";

const Checkout = (props) => {
  const [products, setProducts] = useState([]);
  const [payhereCredentials, setPayhereCredentials] = useState([]);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const cartItems = useSelector((store) => store.ui.cartItems);
  const token = useSelector((store) => store.auth.token);

  const formRef = useRef();

  const [isSubmiting, setIsSubmiting] = useState(false);
  const { isLoading, errors, sendRequest, validationErros } = useRequest();
  const { setOne, inputs } = useForm({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: "",
    city: "",
    country: "",
  });

  const getProducts = useCallback(async () => {
    const response = await sendRequest({
      url: "/cart",
      method: "POST",
      body:
        location.pathname == "/checkout"
          ? [
              {
                id: Math.random().toString(16).slice(2),
                productId: searchParams.get("productId"),
                quantity: searchParams.get("quantity"),
              },
            ]
          : cartItems,
    });
    if (response) {
      setProducts(response);
    }
  }, [cartItems, location.pathname, searchParams, sendRequest]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading && !isSubmiting) {
      const response = await sendRequest({
        url: "/orders",
        method: "POST",
        token,
        body: {
          ...inputs,
          products: products.map((pr) => ({
            product_id: pr.productId,
            quantity: pr.quantity,
          })),
        },
      });
      if (response) {
        setPayhereCredentials(response);
      }
    }
  };

  useEffect(() => {
    if (payhereCredentials?.hash) {
      setIsSubmiting(true);
      formRef.current && formRef.current.submit();
    }
  }, [payhereCredentials]);
  useEffect(() => {
    getProducts();
  }, [getProducts]);
  return (
    <>
      <div className="">
        <Navbar />
        {/* CONTENT */}
        <section class="section">
          <div class="container">
            {products?.length > 0 && (
              <form onSubmit={onFormSubmit} class="row">
                <div class="col-lg-7 col-md-7">
                  <div class="me-lg-4">
                    <div class="row g-4">
                      <div className="card activity activity-primary rounded-md shadow p-4">
                        <h5 class="mb-4">Billing Address</h5>
                        <div className="py-3 border-top row g-3">
                          <Input
                            containerClass="col-12 col-sm-6"
                            label="first name"
                            set={setOne}
                            name="first_name"
                            model={inputs.first_name}
                            error={validationErros?.first_name}
                          />
                          <Input
                            containerClass="col-12 col-sm-6"
                            label="last name"
                            set={setOne}
                            name="last_name"
                            model={inputs.last_name}
                            error={validationErros?.last_name}
                          />
                          <Input
                            containerClass="col-12"
                            label="email address"
                            set={setOne}
                            name="email"
                            model={inputs.email}
                            error={validationErros?.email}
                          />
                          <Input
                            containerClass="col-12"
                            label="phone number"
                            set={setOne}
                            name="phone_number"
                            model={inputs.phone_number}
                            error={validationErros?.phone_number}
                          />
                          <Input
                            containerClass="col-12"
                            label="address"
                            set={setOne}
                            name="address"
                            model={inputs.address}
                            error={validationErros?.address}
                          />
                          <Input
                            containerClass="col-12 col-sm-6"
                            label="city"
                            set={setOne}
                            name="city"
                            model={inputs.city}
                            error={validationErros?.city}
                          />
                          <Input
                            containerClass="col-12 col-sm-6"
                            label="country"
                            set={setOne}
                            name="country"
                            options={countryList.map((name) => ({
                              label: name,
                              value: name,
                            }))}
                            model={inputs.country}
                            error={validationErros?.country}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                  <div class="sticky-bar">
                    <div class="p-4 rounded-md shadow ">
                      <h5 class="mb-2">Order Summary</h5>
                      <div className="py-3 border-top">
                        <div className="d-flex justify-content-between">
                          <p className="fw-bold small"> Subtotal:</p>
                          <p className="text-primary">
                            $
                            {products
                              .reduce(
                                (prev, cartProduct) =>
                                  prev +
                                  cartProduct.quantity *
                                    cartProduct.product.price,
                                0
                              )
                              .toFixed(2)}{" "}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="fw-bold small"> Service free:</p>
                          <p className="text-primary"> $0.00 </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="fw-bold small"> Total:</p>
                          <p className="text-primary mb-0">
                            {" "}
                            $
                            {products
                              .reduce(
                                (prev, cartProduct) =>
                                  prev +
                                  parseInt(cartProduct.quantity) *
                                    parseFloat(cartProduct.product.price),
                                0
                              )
                              .toFixed(2)}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="">
                        <Button isLoading={isLoading}>
                          <i className="mdi mdi-cart fs-5 me-2"></i> Checkout
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </section>
        <Footer />
      </div>

      {/****************************************
       PAYHERE FORM
       ****************************************/}

      <form
        ref={formRef}
        method="post"
        action={`${process.env.REACT_APP_PAYHERE}/pay/checkout`}
      >
        {Object.keys(payhereCredentials).map((key) => (
          <input type="hidden" name={key} value={payhereCredentials[key]} />
        ))}
        {Object.keys(inputs).map((key) => {
          if (key == "email") {
            return (
              <input
                type="hidden"
                name={key}
                value={"payments@codebazaar.net"}
              />
            );
          } else {
            return <input type="hidden" name={key} value={inputs[key]} />;
          }
        })}
        <input
          type="hidden"
          name="return_url"
          value={`${window.location.origin}/orders/complete`}
        />
        <input
          type="hidden"
          name="cancel_url"
          value={`${window.location.origin}/`}
        />
      </form>
    </>
  );
};

export default Checkout;
