import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { paramsToObject } from "../utils/methods";

const SearchBar = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [keywords, setKeywords] = useState(
    paramsToObject(searchParams).keywords
  );
  const navigate = useNavigate();
  const onSearch = (e) => {
    e.preventDefault();
    // if (keywords.trim().length > 0) {
    navigate({
      pathname: "/explore",
      search:
        "?" +
        new URLSearchParams({
          ...paramsToObject(searchParams),
          keywords: keywords,
        }).toString(),
    });
    // }
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="features-absolute">
            <div className="row justify-content-center" id="reserve-form">
              <div className="col-xl-10 mt-lg-5">
                <div className="card bg-white feature-top border-0 shadow rounded p-3">
                  <form onSubmit={onSearch}>
                    <div className="registration-form text-dark text-start">
                      <div className="row g-lg-0">
                        <div className="col-lg-9 col-md-9">
                          <div className="filter-search-form position-relative filter-border">
                            <i className="uil uil-search icons"></i>
                            <input
                              onChange={(e) => setKeywords(e.target.value)}
                              value={keywords}
                              name="name"
                              type="text"
                              id="search-keyword"
                              className="form-control filter-input-box bg-light border-0"
                              placeholder="Search your keywords"
                            />
                          </div>
                        </div>
                        {/*end col*/}

                        {/*end col*/}

                        <div className="col-lg-3 col-md-6 mt-3 mt-lg-0">
                          <input
                            type="submit"
                            id="search"
                            name="search"
                            style={{ height: 60 }}
                            className="btn btn-primary rounded-md searchbtn submit-btn w-100"
                            value="Search"
                          />
                        </div>
                        {/*end col*/}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end container*/}
                  </form>
                </div>
              </div>
              {/*ed col*/}
            </div>
            {/*end row*/}
          </div>
        </div>
        {/*end col*/}
      </div>
      {/*end row*/}
    </div>
  );
};

export default SearchBar;
