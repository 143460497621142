import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "alert",
  initialState: {
    token: null,
    user: null,
    isAuthenticated: false,
  },
  reducers: {
    onLoggedIn: (state, action) => {
      const data = action.payload;
      localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, data.token);
      return {
        ...state,
        token: data.token,
        user: data.user,
        isAuthenticated: data.user != null,
      };
    },

    onLogout: (state, action) => {
      localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
      return {
        token: null,
        user: null,
        isAuthenticated: false,
      };
    },

    initialize: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
