export const storage = (link) => {
  return process.env.REACT_APP_BACKEND_STORAGE + link;
};

export const paramsToObject = (searchParams) => {
  let object = {};
  for (const [key, value] of searchParams.entries()) {
    object[key] = value;
  }
  return object;
};
