import Spinner from "../Spinner";

const Button = ({ isLoading, children }) => {
  return (
    <button className="btn btn-primary rounded-md w-100" type="submit">
      {isLoading ? <Spinner /> : children}
    </button>
  );
};

export default Button;
