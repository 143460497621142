import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { bg01 } from "../../components/imageImport";
import useRequest from "../../hooks/use-request";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { storage } from "../../utils/methods";
import DownloadCard from "../../components/download-card";

const Downloads = (props) => {
  const navigate = useNavigate();
  const { isLoading, sendRequest } = useRequest();
  const [orderItems, setOrderItems] = useState([]);

  const token = useSelector((store) => store.auth.token);
  useEffect(() => {
    const getOrders = async () => {
      let response = await sendRequest({
        url: "/orders",
        token,
      });
      if (response) {
        setOrderItems(response);
      }
    };

    getOrders();
  }, [sendRequest, token]);
  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Start Breadcrumb */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Downloads
                </h5>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul
                className="breadcrumb breadcrumb-muted mb-0 p-0"
                style={{ backgroundColor: "transparent" }}
              >
                <li className="breadcrumb-item">
                  <Link to="/">Code Bazaar</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Downloads
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/*end container*/}
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}

      {/* Start Content */}
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-7">
              <div className="me-lg-4">
                {isLoading && <p className="text-muted d-block">Loading...</p>}

                {!orderItems?.length > 0 && !isLoading && (
                  <p className="text-muted d-block">
                    Oops!, No downloads found :(
                  </p>
                )}
                {orderItems?.length > 0 && (
                  <>
                    <p className="text-muted d-block">
                      You should download your purchases immediately as items
                      may be removed from time to time.
                    </p>

                    <div className="row g-4">
                      {orderItems?.map((data, index) => {
                        return <DownloadCard orderItem={data} />;
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/* End Content */}
      {/* footer */}
      <Footer />
    </>
  );
};

export default Downloads;
