import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth-reducer";
import uiReducer from "./ui-reducer";

const store = configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
  },
});

export default store;
