import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { whiteLogo } from "../../components/imageImport";
import { useDispatch } from "react-redux";
import useForm from "../../hooks/use-form";
import useRequest from "../../hooks/use-request";
import { authActions } from "../../store/auth-reducer";
import Input from "../../components/input";
import Button from "../../components/Button";

const Register = () => {
  const navigate = useNavigate();
  const { setOne, inputs } = useForm({
    email: "",
    password: "",
    name: "",
  });
  const { isLoading, errors, sendRequest, validationErros } = useRequest();
  const dispatch = useDispatch();
  const onFormSubmit = async (e) => {
    e.preventDefault();
    const response = await sendRequest({
      url: "/register",
      method: "POST",
      body: inputs,
    });
    if (response) {
      dispatch(authActions.onLoggedIn(response));
      navigate("/");
    }
  };
  return (
    <>
      {/*  Hero Start  */}
      <section className="position-relative">
        <div className="bg-video-wrapper">
          {/* <iframe src="https://player.vimeo.com/video/502163294?background=1&autoplay=1&loop=1&byline=0&title=0"></iframe> */}
          {/* Note: Vimeo Embed Background Video */}

          {/*  <iframe src="https://www.youtube.com/embed/yba7hPeTSjk?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1"></iframe>  */}
          {/* Note: Youtube Embed Background Video */}
        </div>
        <div
          className="bg-overlay"
          style={{
            background: "rgb(76,70,212)",
            background:
              "linear-gradient(90deg, rgba(76,70,212,1) 0%, rgba(0,212,255,1) 100%)",
          }}
        ></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="d-flex flex-column min-vh-100 p-4">
                {/*  Start Logo  */}
                <div className="text-center">
                  <Link to="/">
                    <img src={whiteLogo} alt="" style={{ width: "250px" }} />
                  </Link>
                </div>
                {/*  End Logo  */}

                {/*  Start Content  */}
                <div className="title-heading text-center my-auto">
                  <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                    <form onSubmit={onFormSubmit}>
                      <h5 className="mb-4">Register Your Account</h5>

                      <div className="row g-3">
                        <Input
                          containerClass="col-lg-12"
                          label="name"
                          set={setOne}
                          name="name"
                          model={inputs.name}
                          error={validationErros?.name}
                        />
                        <Input
                          containerClass="col-lg-12"
                          label="email"
                          set={setOne}
                          name="email"
                          model={inputs.email}
                          error={validationErros?.email}
                        />
                        <Input
                          containerClass="col-lg-12"
                          name="password"
                          set={setOne}
                          model={inputs.password}
                          error={validationErros?.password}
                          label="password"
                          type={"password"}
                        />

                        {/* end col */}

                        <div className="col-lg-12 mt-5">
                          <Button isLoading={isLoading}>Register</Button>
                        </div>
                        {/* end col */}

                        <div className="col-12 text-center mt-4">
                          <small>
                            <span className="text-muted me-2">
                              Already have an account ?{" "}
                            </span>{" "}
                            <Link to={"/login"} className="text-dark fw-bold">
                              Sign in
                            </Link>
                          </small>
                        </div>
                        {/* end col */}
                      </div>
                      {/* end row */}
                    </form>
                  </div>
                </div>
                {/*  End Content  */}

                {/*  Start Footer  */}
                <div className="text-center">
                  <small className="mb-0 text-light title-dark">
                    <p className="mb-0">
                      Copyright ©{" "}
                      <script>document.write(new Date().getFullYear())</script>{" "}
                      2014-2023 Code Bazaar.
                    </p>
                  </small>
                </div>
                {/*  End Footer  */}
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      {/*  Hero End  */}
    </>
  );
};

export default Register;
