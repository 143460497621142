import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Provider } from "react-redux";
import PageLoading from "../components/PageLoading";
import useRequest from "../hooks/use-request";
import { authActions } from "./auth-reducer";
import { uiAction } from "./ui-reducer";

const StoreInitialize = (props) => {
  const { error, isLoading, sendRequest } = useRequest(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useDispatch();

  // Initialize store values
  useEffect(() => {
    let token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

    const initialize = async () => {
      const data = await sendRequest({
        url: "/initialize",
        token: token,
      });
      if (data) {
        dispatch(
          uiAction.initialize({
            categories: data.categories,
          })
        );
        dispatch(
          authActions.initialize({
            token: token,
            isAuthenticated: data.user != null,
            user: data.user,
          })
        );
        setIsInitialized(true);
      }
    };
    initialize();
  }, [dispatch, sendRequest]);
  return (
    <>
      {isLoading && <PageLoading />}
      {error && error}
      {isInitialized && !error && props.children}
    </>
  );
};

export default StoreInitialize;
