import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { bg01, office } from "../../components/imageImport";
import { useDispatch } from "react-redux";
import useRequest from "../../hooks/use-request";
import useForm from "../../hooks/use-form";
import Input from "../../components/input";
import Textarea from "../../components/textarea";
import Button from "../../components/Button";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";

const ContactUs = () => {
  const { setOne, inputs, setFields, reset } = useForm({
    email: "",
    name: "",
    subject: "",
    comments: "",
  });
  const { isLoading, errors, sendRequest, validationErros } = useRequest();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      let response = await sendRequest({
        url: "/messages",
        method: "POST",
        body: inputs,
      });

      if (response) {
        reset();
        addAlert(
          toast.TYPE.SUCCESS,
          "Your messsage sent successfully, we will reach you soon via email"
        );
      }
    }
  };

  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Contact Us
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Get in Touch !
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul
                className="breadcrumb breadcrumb-muted mb-0 p-0"
                style={{ backgroundColor: "transparent" }}
              >
                <li className="breadcrumb-item">
                  <Link to={"/"}>Code Bazaar</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}

      {/* Start Section */}
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="card border-0 text-center features feature-primary feature-clean">
                <div className="icons text-center mx-auto">
                  <i className="uil-users-alt d-block rounded-md h3 mb-0"></i>
                </div>
                <div className="content mt-4 px-4">
                  <h5 className="fw-bold">Join Us</h5>
                  <p className="text-muted">Start working with Code Bazaar</p>
                  <a href="mailto:info@codebazaar.net" className="text-primary">
                    developer@codebazaar.net
                  </a>
                </div>
              </div>
            </div>
            {/*end col*/}

            <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="card border-0 text-center features feature-primary feature-clean">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-envelope d-block rounded-md h3 mb-0"></i>
                </div>
                <div className="content mt-4 px-4">
                  <h5 className="fw-bold">Email</h5>
                  <p className="text-muted">
                    Contact Code Bazaar for more info
                  </p>
                  <a href="mailto:info@codebazaar.net" className="text-primary">
                    info@codebazaar.net
                  </a>
                </div>
              </div>
            </div>
            {/*end col*/}

            <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
              <div className="card border-0 text-center features feature-primary feature-clean">
                <div className="icons text-center mx-auto">
                  <i className="uil-github d-block rounded-md h3 mb-0"></i>
                </div>
                <div className="content mt-4 px-4">
                  <h5 className="fw-bold">Support</h5>
                  <p className="text-muted">
                    Get technical support from Code Bazaar
                  </p>
                  <a href="mailto:info@codebazaar.net" className="text-primary">
                    support@codebazaar.net
                  </a>
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
              <div className="card shadow rounded border-0">
                <div className="card-body py-5">
                  <h4 className="card-title">Get In Touch !</h4>
                  <div className="custom-form mt-3">
                    <form onSubmit={onFormSubmit} name="myForm">
                      <p className="mb-0" id="error-msg"></p>
                      <div id="simple-msg"></div>
                      <div className="row g-3">
                        <Input
                          containerClass="col-md-6"
                          label="name"
                          set={setOne}
                          name="name"
                          model={inputs.name}
                          error={validationErros?.name}
                        />
                        <Input
                          containerClass="col-md-6"
                          label="email"
                          set={setOne}
                          name="email"
                          model={inputs.email}
                          error={validationErros?.email}
                        />

                        {/*end col*/}

                        <Input
                          containerClass="col-12"
                          label="subject"
                          set={setOne}
                          name="subject"
                          model={inputs.subject}
                          error={validationErros?.subject}
                        />
                        {/*end col*/}

                        <Textarea
                          containerClass="col-12"
                          label="comments"
                          set={setOne}
                          name="comments"
                          model={inputs.comments}
                          error={validationErros?.comments}
                        />
                      </div>
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="d-grid">
                            <Button isLoading={isLoading}>Send Message</Button>
                          </div>
                        </div>
                        {/*end col*/}
                      </div>
                      {/*end row*/}
                    </form>
                  </div>
                  {/*end custom-form*/}
                </div>
              </div>
            </div>
            {/*end col*/}

            <div className="col-lg-7 col-md-6 order-1 order-md-2">
              <div className="card border-0">
                <div className="card-body p-0">
                  <img
                    src={office}
                    className="img-fluid d-block mx-auto"
                    style={{ maxWidth: 550 }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}

      {/*end container*/}
      {/* End Section */}

      {/* footer */}
      <Footer />
    </>
  );
};

export default ContactUs;
