import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import PageLoading from "../../components/PageLoading";
import useRequest from "../../hooks/use-request";
import { uiAction } from "../../store/ui-reducer";
import { storage } from "../../utils/methods";

const OrderComplete = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading, sendRequest } = useRequest();

  const token = useSelector((store) => store.auth.token);
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let orderId = searchParams.get("order_id");

    const verifyOrder = async () => {
      let response = await sendRequest({
        url: "/payments/status",
        method: "POST",
        body: {
          order_id: orderId,
        },
        token,
      });

      if (response) {
        dispatch(uiAction.emptyCart());
        setStatus(response);
      }
    };

    if (orderId) {
      verifyOrder();
    } else {
      navigate("/notfound");
    }
  }, [navigate, searchParams, sendRequest, token]);

  return status ? (
    <div className="">
      <Navbar />
      <section className="pt-5 mt-5 mb-5 d-table w-100">
        <div className="title-heading text-center mt-5 py-4 shadow-lg my-auto w-50 mx-auto ">
          <h1 className="heading sub-heading mb-2  text-dark">
            {status == "success" ? "🎉 Yahoooo! 🎉" : "❗ Whoops! ❗"}
          </h1>
          <p
            style={{
              color: status == "success" ? "#47C96A" : "#E86859",
              fontWeight: 600,
              fontSize: "20px",
              marginBottom: "-14px",
            }}
            className="mt-3 pb-0"
          >
            {status == "success" ? "Purchase Completed" : "Purchase Failed"}
          </p>{" "}
          <p className="text-muted mt-0 pt-0">
            <br />
            {status == "success" &&
              "Now you can download your content from downloads page"}
            {status == "pending" &&
              "You payment is in pending! please contact us for further clarification"}
            {status == "canceled" &&
              "Your purchase has been canceled. if it is not suppose to be cancelled please contact us"}
            {status == "failed" &&
              "Your purchase has been failed!.  if it is not expected behaviour please contact us for further clarification"}
            {status == "chargedback" &&
              "Your purchase has been charged back!. please contact us for further clarification"}
          </p>
          <div className="mt-4">
            <Link
              to={"/"}
              href=""
              className="back-button mx-2 btn btn-outline-secondary"
            >
              Back to Home
            </Link>
            {status == "success" ? (
              <Link
                to={"/downloads"}
                href=""
                className="back-button mx-2 btn btn-primary"
              >
                Go to Downloads
              </Link>
            ) : (
              <Link
                to={"/contact-us"}
                href=""
                className="back-button mx-2 btn btn-primary"
              >
                Contact Us
              </Link>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  ) : (
    <PageLoading />
  );
};

export default OrderComplete;
