import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { client01, single } from "../../components/imageImport";
import ProductCard from "../../components/product-card";
import useRequest from "../../hooks/use-request";
import { storage } from "../../utils/methods";

const CreateProfile = () => {
  const [creator, setCreator] = useState([]);
  const { isLoading, sendRequest } = useRequest();
  console.log(creator);
  const { creatorId } = useParams();
  const getCreator = useCallback(async () => {
    let response = await sendRequest({
      url: "/creators/" + creatorId,
    });

    if (response) {
      setCreator(response);
    }
  }, [creatorId, sendRequest]);
  useEffect(() => {
    getCreator();
  }, [getCreator]);

  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Start Home */}
      <section className="pt-5 mt-5">
        <div className="container">
          <div className="profile-banner">
            <div className="position-relative d-inline-block">
              <img
                src={storage(creator?.cover_path)}
                className="rounded-md shadow-sm img-fluid"
                id="profile-banner"
                alt=""
              />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col">
              <div className="text-center mt-n80">
                <div className="profile-pic">
                  <div className="position-relative d-inline-block">
                    <img
                      src={storage(creator?.profile_path)}
                      className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm"
                      id="profile-image"
                      alt=""
                    />
                  </div>
                </div>

                <div className="content mt-3">
                  <h5 className="mb-2">{creator?.name}</h5>
                  <p className="mt-2 mb-0 text-muted">{creator.bio}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-4">
          <div className="row">
            <div className="col-12">
              <div className="tab-content mt-4 pt-2" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="CreateItem"
                  role="tabpanel"
                  aria-labelledby="Create-tab"
                >
                  {/* if value select created */}
                  <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4">
                    {creator?.products?.map((data) => (
                      <ProductCard product={data} />
                    ))}
                    {/*end row*/}
                  </div>
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Home */}
      {/* footer */}
      <Footer />
    </>
  );
};

export default CreateProfile;
