import React, { forwardRef } from "react";

import styles from "./input.module.css";
const Textarea = forwardRef(
  (
    {
      size,
      label,
      icon,
      containerClass,
      type,
      error,
      set,
      model,
      name,
      placeholder,
      options,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`${styles.inputContainer} ${containerClass}`}>
        <label className="form-label fw-bold text-capitalize">{label}:</label>

        <textarea
          onChange={(e) => {
            set(name, e.target.value);
          }}
          value={model}
          ref={ref}
          {...props}
          type={type ?? "text"}
          placeholder={placeholder ?? `Enter your ${label ?? name}`}
          className="form-control"
          style={{ borderColor: error && "#e23838" }}
          rows={4}
        />

        {error && <span>{error}</span>}
      </div>
    );
  }
);

export default Textarea;
