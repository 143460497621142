import { useDispatch, useSelector } from "react-redux";
import { uiAction } from "../store/ui-reducer";
import { storage } from "../utils/methods";

const CartProduct = ({ cartProduct, dispatch }) => {
  return (
    <div className="card activity activity-primary rounded-md shadow p-4">
      <div className="d-flex align-items-center">
        <div className="position-relative">
          <img
            src={storage(cartProduct.product?.image_path)}
            className="avatar avatar-md-md rounded-md shadow-md"
            alt=""
          />
          <div
            onClick={() =>
              dispatch(
                uiAction.removeFromCart({
                  id: cartProduct.id,
                })
              )
            }
            style={{ cursor: "pointer" }}
            class=" position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white"
          >
            <i class="mdi mdi-close mdi-18px text-danger"></i>
          </div>
        </div>
        <span className="content ms-3">
          <a href="" className="text-dark title mb-0 h6 d-block">
            {cartProduct.product?.name}
          </a>

          <small className="text-muted d-block mt-1">
            <span className="link fw-bold">
              ${cartProduct.product?.price} (x{cartProduct.quantity})
            </span>
          </small>
          <small className="text-muted d-block mt-1">
            Creator: @{cartProduct.product?.creator?.name}
          </small>
        </span>
      </div>
    </div>
  );
};

export default CartProduct;
