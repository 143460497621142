import React, { useEffect } from "react";

import AppRouter from "./router";
import "./choices.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StoreInitialize from "./store/store-initialize";
import store from "./store/store";
import { Provider } from "react-redux";
import { addAlert } from "./utils/alert";
const App = () => {
  useEffect(() => {
    console.log("wake up");
    document.querySelector("body > iframe")?.remove();
  });

  return (
    <Provider store={store}>
      <StoreInitialize>
        <ToastContainer />
        <AppRouter />
      </StoreInitialize>
    </Provider>
  );
};

export default App;
