import axios from "axios";
import { useCallback } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addAlert } from "../utils/alert";

const useRequest = (errorHandle = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [statusCode, setStatusCode] = useState(null);
  const [validationErros, setValidationErros] = useState(null);
  const navigate = useNavigate();

  const onError = useCallback(
    (error) => {
      // setErrors(error.response?.errors ?? [error.message]);
      setStatusCode(error.response.status);
      if (errorHandle) {
        if (error.response.status === 400 || error.response.status === 422) {
          console.log(error);
          // validation error
          if (Array.isArray(error.response?.data?.errors)) {
            setErrors(error.response?.data?.errors);
          } else if (error.response?.data?.errors) {
            setValidationErros(error.response?.data?.errors);
          } else {
            setErrors((prev) => [...prev, error.response.data.message]);
          }
        } else if (
          error.response.status === 404 ||
          error.response.status === 403 ||
          error.response.status === 401
        ) {
          //non exist or not authenticated or not autharized
          navigate("/error", {
            state: {
              code: error.response.status,
              message: "Not Found",
            },
          });
        } else if (error.response.status === 500) {
          addAlert(toast.TYPE.ERROR, "Something went wrong, Please try again");
        }
      }
    },
    [errorHandle, navigate]
  );

  const sendRequest = useCallback(
    async (config) => {
      try {
        setErrors([]);
        setValidationErros(null);
        setIsLoading(true);

        let response = await axios.request({
          method: config.method ?? "GET",
          url: process.env.REACT_APP_BACKEND_API + config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: config.token && "Bearer " + config.token,
            ...config.headers,
          },
          data: config.body,
          params: config.queryParams,
        });
        return response.data;
      } catch (error) {
        onError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [onError]
  );

  const resetValidationErrors = () => {
    setValidationErros(null);
  };

  const sendFormRequest = async (config) => {
    try {
      if (!isLoading) {
        setErrors([]);
        setStatusCode(null);
        setValidationErros(null);
        setIsLoading(true);

        let response = await axios.postForm(
          process.env.REACT_APP_BACKEND_API + config.url,
          config.body,
          {
            headers: {
              Accept: "application/json",
              Authorization: config.token && "Bearer " + config.token,
              ...config.headers,
            },
          }
        );

        return response.data;
      }
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    errors,
    validationErros,
    sendRequest,
    resetValidationErrors,
    sendFormRequest,
    statusCode,
  };
};
export default useRequest;
