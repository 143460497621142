import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { uiAction } from "../store/ui-reducer";
import { storage } from "../utils/methods";
import styles from "./product-card.module.css";

const ProductCard = ({ product }) => {
  const isFav = useSelector((store) => store.ui.favItems).find(
    (id) => id == product.id
  );
  const dispatch = useDispatch();
  return (
    <div className="col" key={product.id}>
      <div className="card nft-items nft-primary nft-auction rounded-md shadow overflow-hidden mb-1 p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={storage(product.creator?.profile_path)}
              alt="user"
              className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
            />
            <Link
              to={`/creators/${product.creator.id}`}
              className="text-dark small creator-name h6 mb-0 ms-2"
            >
              @{product.creator?.name}
            </Link>
          </div>
        </div>

        <div className="nft-image rounded-md mt-3 position-relative overflow-hidden">
          <Link to={`/products/${product.id}`}>
            <img
              src={storage(product.image_path)}
              style={{
                width: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              alt=""
            />
          </Link>
          <div className="position-absolute top-0 start-0 m-2">
            <Link
              to={`/explore?category=${product.category?.id}`}
              className="badge badge-link bg-primary"
            >
              {product.category?.name}
            </Link>
          </div>
          <div
            className="position-absolute top-0 end-0 m-2"
            style={{ cursor: "pointer" }}
          >
            <span className="like-icon shadow-sm">
              <div
                onClick={() => dispatch(uiAction.toggleFav({ id: product.id }))}
                className={`text-muted icon ${isFav && styles.favIconActive}`}
              >
                <i className="mdi mdi-18px mdi-heart mb-0"></i>
              </div>
            </span>
          </div>
        </div>

        <div className="card-body content position-relative p-0 mt-3">
          <Link to={`/products/${product.id}`} className="title text-dark h6">
            {product.name.length > 43
              ? product.name.substring(0, 43) + "..."
              : product.name}
          </Link>

          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="">
              <small className="mb-0 d-block fw-semibold">Price:</small>
              <small className="rate fw-bold">${product.price}</small>
            </div>
            <div
              onClick={() =>
                dispatch(
                  uiAction.addToCart({
                    productId: product.id,
                    quantity: 1,
                  })
                )
              }
              className="btn btn-icon btn-pills btn-primary"
            >
              <i className="uil uil-shopping-bag"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
